.loader-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: $content-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  .loaded & {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .8s ease-out;
    transition: all .8s ease-out
  }
  .loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: theme-color(primary);
    background: -moz-linear-gradient(left, theme-color(primary) 10%, rgba(0,0,0, 0) 42%);
    background: -webkit-linear-gradient(left, theme-color(primary) 10%, rgba(0,0,0, 0) 42%);
    background: -o-linear-gradient(left, theme-color(primary) 10%, rgba(0,0,0, 0) 42%);
    background: -ms-linear-gradient(left, theme-color(primary) 10%, rgba(0,0,0, 0) 42%);
    background: linear-gradient(to right, theme-color(primary) 10%, rgba(0,0,0, 0) 42%);
    -webkit-animation: loading 1.4s infinite linear;
    animation: loading 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    &.loader:before {
      width: 50%;
      height: 50%;
      background: theme-color(primary);
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
    &.loader:after {
      background: $content-bg;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}