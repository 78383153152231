.flot-chart-example-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
  .flot-chart-example {
    width: 100%;
    position: relative;
    max-width: none;
    height: 400px;
  }
  @media(max-width: 767px) {
    height: 200px;
    min-height: 200px;
    .flot-chart-example {
      height: 100%;
    }
  }
}

.flot-text {
  .flot-x-axis,
  .flot-y-axis {
    > div,
    .flot-tick-label {
      @extend .text-muted;
    }
  }
}