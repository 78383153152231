// Miscellaneous

body,
html {
  padding-right: 0 !important; //resets padding right added by Bootstrap modal
}

body {
    margin: 0;
    color: $body-color;
    text-align: left;
    background-color: $content-bg;
    height: 100%;
  }

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-full-screen {
  overflow: auto;
}


pre {
  background-color: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  font-family: $type1;
  font-weight: 400;
  font-size: $default-font-size;
  border-radius: 4px;
}